import React from "react";
import "./App.css";

import Logo from "../src/img/logo.jpg";

function App() {
  return (
    <div className="App" style={{ marginTop: "15px" }}>
      <img alt="" src={Logo} />
    </div>
  );
}

export default App;
